import "styles/reset.css"
import "styles/slick-theme.css"
import "styles/slick.css"
import * as styles from "styles"
import styled from "@emotion/styled"

import React, { useEffect, useState, Suspense } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Seo from "./seo"
import Header from "./header"
import Footer from "./footer"
import SecretFooter from "./secretFooter"

const siteQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        socialImage
        siteUrl
        titles {
          pathname
          title
          nav
        }
      }
    }
  }
`

const ScrollWrapper = styled.div`
  background-color: ${styles.palette.off_white};
`

const Layout = ({ location, children }) => {
  let path = location ? location.pathname : ""
  const [isOurStory, setIsOurStory] = useState(path == "/what-we-do/" || path == "/what-we-do");
  const [isHome, setIsHome] = useState(path == "/" || path == "/home" || path == "/home/")
  const { site } = useStaticQuery(siteQuery)

  const [footerShown, setFooterShown] = useState(false)
  const [footerZindex, setFooterZindex] = useState(-1);
  const [vph, setVPH] = useState(0)

  useEffect(() => {
    setVPH(window.innerHeight);
    setTimeout(() => window.scrollTo(0, 0), 500)
  }, [])

  useEffect(() => {
    setIsOurStory(path == "/what-we-do/" || path == "/what-we-do");
    setIsHome(path == "/" || path == "/home" || path == "/home/");
    setTimeout(() => window.scrollTo(0, 0), 500)
  }, [location])

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Seo siteMetadata={site.siteMetadata} />
        <Header hasHero={isHome || isOurStory} siteMetadata={site.siteMetadata} location={location} footerZindex={footerZindex}/>
        <ScrollWrapper>
          {children}
        </ScrollWrapper>
        { vph !== 0 && <SecretFooter setFooterShown={setFooterShown} setFooterZindex={setFooterZindex} vph={vph}/> }
        <Footer siteMetadata={site.siteMetadata} footerShown={footerShown} footerZindex={footerZindex}/>
      </Suspense>
    </>
  )
}

export default Layout
