import * as styles from "styles"
import styled from "@emotion/styled"
import { useInView } from 'react-intersection-observer';
import React, { useEffect, useState } from "react"

const Wrapper = styled.div`
    width: 100vw;
    height: ${({height}) => height}px;
    pointer-events: none;
    // background-color: rgba(255,255,255, 0.2;
`


const SecretFooter = ({setFooterShown, setFooterZindex, vph}) => {

    const onChangeView = (inview, entry) => {
      if(entry.intersectionRatio >= 0.99) {
        // console.log('footer zindex 1')
        setFooterZindex(1)
      }
      else {
        // console.log('footer zindex -1')
        setFooterZindex(-1)
      }
    }

    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: [0, 0.99],
        onChange: onChangeView,
    });

    useEffect(() => {
        setFooterShown(inView)
    }, [inView])
    
  return (
    <Wrapper ref={ref} height={vph}/>
  )
}
export default SecretFooter