import React from "react"
import Helmet from "react-helmet"
import { globalHistory } from "@reach/router"

const SEO = ({ siteMetadata }) => {
  const title = siteMetadata.titles.reduce(
    (acc, t) =>
      t.pathname === globalHistory.location.pathname ? t.title : acc,
    ""
  )

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={[
        {
          property: `author`,
          content: siteMetadata.author,
        },
        {
          name: `description`,
          content: siteMetadata.description,
        },
        // {
        //   property: `og:title`,
        //   content: title,
        // },
        {
          property: `og:description`,
          content: siteMetadata.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${siteMetadata.siteUrl}/${siteMetadata.socialImage}`,
        },
        {
          property: "og:image:width",
          content: 300,
        },
        {
          property: "og:image:height",
          content: 300,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: siteMetadata.description,
        },
        {
          name: 'theme-color',
          content: '#F0EFEA'
        }
      ]}
    />
  )
}

export default SEO
