import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import * as styles from "styles"

let previousScroll = 0;

const Item = styled(styles.text_)`
  opacity: ${({footerZindex}) => footerZindex === 1 ? 0:1};
  font-weight: 100;
  color: ${({ scrolled, hasHero }) =>
  hasHero ? (!scrolled ? styles.palette.white : styles.palette.black) : styles.palette.black};
  border-bottom: ${({selected}) => selected ? '1px solid white':'none'};
  border-color: ${({ scrolled, hasHero }) =>
  hasHero ? (!scrolled ? styles.palette.white : styles.palette.black) : styles.palette.black};
  :hover {
    opacity: 0.8 !important;
  }

  ${styles.media.tablet`
    color: unset;
    border-bottom: none;
  `}

  ${styles.media.mobile`
    color: unset;
    border-bottom: none;
  `}
`

const Items = styled.ul`
  ${styles.media.tablet`
    height: 100%;
    width: 100%;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    li {
      a div {
        color: ${styles.palette.white};
        }
      }
    }
  `};

  li {
    margin: 43px 33px;
  }

  @media (max-width: ${styles.sizes.tablet}px) {
    padding: 120px 15px 15px 15px;
    top: ${props => (props.active ? "0" : "-120vh")};
    transition-duration: .5s;
    transition-property: top;
    transition-delay: ${props => (props.active ? '0s':'.5s')};
    background: #101010;
    li a div {
      font-size: 12vw !important;
      font-weight: normal;
      opacity: ${props => (props.active ? "1" : "0")};
      margin-top: ${props => (props.active ? "0px" : "20px")};
      margin-bottom: ${props => (props.active ? "0px" : "-20px")};
      transition-duration: 1s;
    }

    li:nth-child(1) {
      a div {
        transition-delay: ${props => (props.active ? '.8s':'0s')};
      }
    }
    li:nth-child(2) {
      a div {
        transition-delay: ${props => (props.active ? '1s':'0s')};
      }
    }
    li:nth-child(3) {
      a div {
        transition-delay: ${props => (props.active ? '1.2s':'0s')};
      }
    }
    li:nth-child(4) {
      a div {
        transition-delay: ${props => (props.active ? '1.4s':'0s')};
      }
    }

    li {
      margin: 0;
      display: block;
    }
  }
`


const HeaderOuter = styled.header`
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: all 0.2s ease-in-out;
  height:  ${({ transparentBackground }) => transparentBackground ? '90':'110'}px;
  background-color: ${({ scrolled, hasHero, transparentBackground }) =>
  hasHero ? ((!scrolled) ? 'transparent' : styles.palette.off_white) : transparentBackground ? 'transparent':styles.palette.off_white};
  margin-top: ${({headerHidden}) => headerHidden ? '-110px':'0'};
  transition-duration: .5s;
  transition-property: margin-top;


  ${styles.media.tablet`
    height: 60px;
  `}
  ${styles.media.mobile`
    height: 60px;
  `}
`

const HeaderInner = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const HomeLink = styled(Link)`
  position: fixed;
  top: ${({ active }) =>
  active ? 'unset !important':'33px'};
  bottom: ${({ active }) =>
  active ? '15px':'unset !important'};
  margin-top: ${({headerHidden}) => headerHidden ? '-110px':'0'};
  height: fit-content;
  left: 33px;
  z-index: 2;
  filter: invert(${({ scrolled, hasHero, active }) =>
    active ? 1 : hasHero ? (!scrolled ? '1' : '0') : '0'});
  transition-property: filter, bottom, top, margin-top;
  transition-delay: ${({ active }) =>
  active ? '.6s, .6s, .6s, .1s':'0s, 0s, 0s, 0.1s'};
  transition-duration: 0s, 0s, 0s, 0.5s;
  opacity: ${({ active }) => active ? 0:1};
  animation: ${({ active }) => active ? 'opacityChangeMobile 3s forwards 0.2s ease-in-out':''};

  @keyframes opacityChangeMobile {
    0% { opacity: 0 }
    100% { opacity: 1 }
  }

  img {
    width 120px;
  }

  ${styles.media.tablet`
    top: 18px;
    left: 18px;
    img {
      width 98px;
    }
  `}
  ${styles.media.mobile`
    top: 15px;
    left: 15px;
    img {
      width 83px;
    }
  `}

  img {
    width: ${({ active }) =>
    active ? 'calc(100vw - 30px)':''};
    transition-property: width;
    transition-delay: ${({ active }) =>
  active ? '.6s':'0s'};
  }

`

const ButtonWrap = styled.div`
  top: 22px;
  right: 18px;
  position: fixed;
  display: none;
  // margin-top: ${({headerHidden}) => headerHidden ? '-0px':'0'};
  transition-duration: 0.5s;
  transition-property: margin-top;
  
  div {
    color: ${({ scrolled, hasHero, active }) =>
    active ? styles.palette.white : hasHero ? (!scrolled ? styles.palette.white : styles.palette.black) : styles.palette.black};
  }

  ${styles.media.tablet`
  display: block;
`}
  ${styles.media.mobile`
  display: block;
  right: 15px;
  `}
`


const Header = ({ hasHero, siteMetadata, location, footerZindex }) => {

  let [toggle, setToggle] = React.useState(false)
  const [isScroll, setIsScroll] = React.useState(false)
  const [headerHidden, setHeaderHidden] = React.useState(false);

  const onScroll = () => {
    // if(location.pathname !== '/our-jobs' && location.pathname !== '/our-jobs/') {
      if(window.scrollY > previousScroll) setHeaderHidden(true)
      if((window.scrollY < (previousScroll - 5) || window.scrollY <= 0) && (window.scrollY < document.body.scrollHeight - 100)) {
        setHeaderHidden(false)
        setIsScroll(window.scrollY >= 100 - 33)
      }
      previousScroll = window.scrollY
    // }
    // else {
    //   setIsScroll(window.scrollY >= 100 - 33)
    // }
  }

  React.useEffect(() => {
    document.body.style.overflowY = toggle ? 'hidden':'scroll';
  }, [toggle] )

  React.useEffect(() => {
      window.addEventListener("scroll", onScroll)
      setToggle(false)
      window.addEventListener("resize", () => { if(window.innerWidth > 900) setToggle(false)})
      return () => {
        window.removeEventListener("scroll", onScroll)
        window.removeEventListener("resize", () => { if(window.innerWidth > 900) setToggle(false)})
      }
  },[]);

  return (
    <HeaderOuter scrolled={isScroll} onMouseEnter={() => setHeaderHidden(false)} hasHero={hasHero} headerHidden={headerHidden}
    transparentBackground={location.pathname.indexOf('our-jobs') > -1} id="header"
    >
      <HeaderInner headerHidden={headerHidden}>
        <HomeLink onClick={_ => setToggle(false)} to="/home" hasHero={hasHero} scrolled={isScroll} active={toggle} headerHidden={headerHidden}>
            <img src="/logo.svg" />
        </HomeLink>
        <Items active={toggle} hasHero={hasHero} scrolled={isScroll}>
          {siteMetadata.titles
            .filter(x => x.nav)
            .map(({ pathname, title }, key) => (
              <li>
                <Link onClick={_ => setToggle(false)} to={pathname} key={pathname}>
                  <Item selected={location.pathname === pathname || location.pathname === `${pathname}/`} hasHero={hasHero} scrolled={isScroll}>{title}</Item>
                </Link>
              </li>
            ))}
        </Items>
        <ButtonWrap hasHero={hasHero} scrolled={isScroll} active={toggle} headerHidden={headerHidden}>
          <Item onClick={_ => setToggle(!toggle)} footerZindex={footerZindex}>{toggle ? 'Close':'Menu'}</Item>
        </ButtonWrap>
      </HeaderInner>
    </HeaderOuter>
  )
}

export default Header
